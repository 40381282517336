import styled from 'styled-components';
import bg from '../Images/bg1.svg';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import OutlinedInput from '@mui/material/OutlinedInput';

// Media Query Break Points
const sizes = {
    tablet: '48em',      // 768px
    laptop: '64em',      //1024px,
    laptopL: '85.375em', //1440px
    desktop: '160em',    //2560px,
  };
  
  // Devices
  export const devices = {
    tablet: `(min-width: ${sizes.tablet})`,
    laptop: `(min-width: ${sizes.laptop})`,
    laptopL: `(min-width: ${sizes.laptopL})`,
    desktop: `(min-width: ${sizes.desktop})`,
  };

export const AuthContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
background-color: #fafbfc;
background-image: url(${bg});
background-repeat: no-repeat;
background-size: 100%;
background-attachment: fixed; 
background-position: 0 110%;
width: 100%;
height: calc(100vh - 15vh);

@media ${devices. laptopL} {
   width: 100%;
   height: 100vh;
  }

  @media ${devices.desktop} {
   background-image: url(${bg});
   background-repeat: no-repeat;
   background-size: 100%; 
   background-attachment: fixed; 
   background-position: 0 100%;
   width: 100%;
   height: 100vh;
  }
`

export const inputStyle = styled.input`
    padding : 30px;
    height : 5rem;
    width : 100%;
    margin-Top : 2.3rem;
`;

export const LoginContainer = styled.div`
    margin-top: -30vh;
    width: 470px;
    background: #fff;
    box-shadow: 0 24px 64px #26214a1a;
    border-radius: 12px;
    padding: 30px 60px;

    @media ${devices.desktop} {
        margin-top: -16vh;
        width: 32%;
    }
`;

export const LogInHeader = styled.h1`
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 2.7rem;
    color: #292d34;
    text-align: center;
    margin-bottom: 20px;

    @media ${devices.desktop} {
       padding: 3%;
      font-size: 4rem;
    }
`;

export const AuthForm = styled.form`
width: 100%;
@media ${devices.desktop} {
  justify-content: space-evenly;
  height: 80%;
}
`;



export const PasswordLinkButton = styled.button`
 cursor: pointer;
 border: none;
 width: 100%;
 margin: 0 auto;
 font-size: 15px;
 font-weight: 400;
 line-height: 1;
 color: #7b68ee;
 background-color: #fff;
  @media ${devices. desktop} {
   font-size: 2rem;
   padding: 3%;
  }
`;

export const LogInSpiner = styled(CircularProgress)`
height: 97%;
color: red;
margin: 0;
`;

export const AlertContainer = styled(Stack)`
 display: flex;
 align-items: center;
 justify-content: center;
 width: 100%;
 margin-bottom: 8px;
 color: red;
`;

export const AlertMessage = styled(Alert)`
width: 100%;
 background-color: #fff;
`;