import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
    name: 'auth',
    initialState: { token: null , user: []},
    reducers: {
        setCredentials: (state, action) => {
            console.log(action.payload)
            const accessToken = action.payload.payloadData
            //const accessToken = action.payload.response_data.payload
            state.token = accessToken // token
        },
        setCredentialsEmailPassWord: (state, action) => {
            const authData = action.payload
            state.user = authData
        },
        logOut: (state, action) => {
            state.token = null
        },
    }
})

export const { setCredentials, logOut, setCredentialsEmailPassWord } = authSlice.actions

export default authSlice.reducer

export const selectCurrentToken = (state) => state.auth.token