import styled from 'styled-components';

export const Footer =  styled.footer`
    display: flex;
    position: fixed;
    bottom: 0;
    width: 50%;
    font-family: 'IBM Plex Sans Condensed', sans-serif;
    margin: auto;
    padding-bottom: 3%;
    elevation: 5;
    color: #ffffff;
    justify-content: center;
    @media (max-width: 1000px) {
        padding: 0.9%;
    }
`;

export const SmallFooterText = styled.span`
    font-size: 8px;
    color: whitesmoke;
`;
