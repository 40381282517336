import styled from "@emotion/styled";

// Media Query Break Points
const sizes = {
  tablet: '48em',      // 768px
  laptop: '64em',      //1024px,
  laptopL: '85.375em', //1440px
  desktop: '160em',    //2560px,
};

// Devices
export const devices = {
  tablet: `(min-width: ${sizes.tablet})`,
  laptop: `(min-width: ${sizes.laptop})`,
  laptopL: `(min-width: ${sizes.laptopL})`,
  desktop: `(min-width: ${sizes.desktop})`,
};

const StyledButton = ({
  className,
  children,
  dataTestId,
  disabled,
  onClick,
  style,
  type
}) => (
  <button
    data-testid={dataTestId}
    disabled={disabled}
    type={type}
    className={className}
    onClick={onClick}
    style={style}
  >
    {children}
  </button>
);

const Button = styled(StyledButton)`
  cursor: ${({ disabled }) => (!disabled ? "pointer" : "not-allowed")};
  display: ${({ display }) => display || "block"};
  background-color: #081398;
  color: #fff;
  border: 1px solid transparent;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  border-radius: 10px;
  width: ${({ width }) => width || "100%"};
  padding: ${({ padding }) => padding || "15px"};
  font-size: ${({ fontSize }) => fontSize || "15px"};
  margin: ${({ margin }) => margin || "auto"};
  max-width: ${({ maxWidth }) => maxWidth || "auto"};
  letter-spacing: 1px;

  @media ${devices.desktop} {
    margin-top: 1.2rem;
    padding: 2rem;
    font-size: 1.6rem;
    width: 94%;
    margin-left: 3%;

}

  :hover {
    background-color: #0f7ae5;
    color: #fff;
  }

  :focus {
    outline: 0;
  }
`;

export default Button;
