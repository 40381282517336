import { apiSlice } from "../../app/api/apiSlice"
import { logOut } from "./authSlice"

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: credentials => ({
                url: '/insureapi/request_handler.php',
                method: 'POST',
                body: { ...credentials }
            }),
            transformResponse: responseData => {
                const payloadData = responseData.response_data.payload
                const code_response =   responseData.response_code
                return { payloadData, code_response}
            },
        }),

        resendOTP: builder.mutation({
            query: credentials => ({
                url: '/insureapi/request_handler.php',
                method: 'POST',
                body: { ...credentials }
            }),
            transformResponse: responseData => {
                const payloadData = responseData.response_data.payload
                const code_response =   responseData.response_code
                return { payloadData, code_response}
            },
            
        }),
        
        
        sendLogout: builder.mutation({
            query: credentials => ({
                url: '/insureapi/request_handler.php',
                method: 'POST',
                body: {...credentials}
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                   // const id = getState().auth.user.id
                    const { data } = await queryFulfilled
                    dispatch(logOut())
                    setTimeout(() => {
                        dispatch(apiSlice.util.resetApiState())
                    }, 1000)
                } catch (err) {
                    return err
                }
            }
        }),
        routes: builder.query({
            query: () => ({
                url: '/insureapi/request_handler.php',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedRoutes = responseData.map(note => {
                });
              //  return 
            },
            providesTags: (result, error, arg) => {
            }
        }),
    })
})

export const {
    useLoginMutation,
    useSendLogoutMutation,
    useResendOTPMutation,
    useRoutesQuery,
} = authApiSlice 

// returns the query result object


