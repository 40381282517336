import styled from 'styled-components';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

export const Navbar =  styled.nav`
    display: flex;
    position: sticky;
    top: 0;
    justify-content: space-between ;
    width: 100%;
    height: 123px;
    background-color: #fafbfc;
    padding: 8px;
    z-index: 99 ;
`;

export const Logo = styled.img`
    cursor: pointer;
    width : 20%;
    margin-top: 5px;
    color: #FFF;
    object-fit: contain;
    font-size: 40px;
`;

export const DashNavbar = styled(Navbar)`
    background-color: #020528;
`;

export const LogoutButton = styled.button`
border: none;
color: #ff8901;
font-size: 1.2rem;
cursor: pointer;
background-color: #020528;

`;

export const ProfileContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
margin-right: 5rem;
`;

export const ProfileIcon = styled(AccountCircleOutlinedIcon)`
background-color: #fff;
margin-left: 0.7rem;
border-radius: 50%;
transform: scale(1.4);
`