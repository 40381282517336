import styled from "styled-components";

// Media Query Break Points
const sizes = {
    tablet: '48em',      // 768px
    laptop: '64em',      //1024px,
    laptopL: '85.375em', //1440px
    desktop: '160em',    //2560px,
  };
  
  // Devices
  export const devices = {
    tablet: `(min-width: ${sizes.tablet})`,
    laptop: `(min-width: ${sizes.laptop})`,
    laptopL: `(min-width: ${sizes.laptopL})`,
    desktop: `(min-width: ${sizes.desktop})`,
  };

export const StyledContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fafbfc;
	background-repeat: no-repeat;
	background-size: 100%;
	background-attachment: fixed;
	background-position: 0 110%;
	width: 100%;
	@media ${devices.laptopL} {
		height:100vh;
    }
	height: calc(100vh - 17vh);
	  @media ${devices.desktop} {
        height:100vh;
    }
	
`;

export const StyledContainer2 = styled.form`
	margin-right: auto;
	margin-left: auto;
	position: absolute;
	top: 28%;
	width: 100vw;
	
	background: #fafbfc;
	align-items: center;

	@media ${devices.laptopL} {
		top: 38%;
		height:100vh;
    }
	height: calc(100vh - 17vh);
	  @media ${devices.desktop} {
        height:100vh;
    }
`;

export const StyledLogo = styled.div`
	position: absolute;
	width: 40vw;
	height: 50vh;
	margin-left: 11px;
	left: 40px;
	top: -57px;
	background: url(${(props) => props.inputImg}) no-repeat;
	background-size: contain;
`;

export const OTPHeader = styled.h6`
    font-size: 2rem;
    font-weight: 500;
    line-height: 40px;
    color: #020528;
    text-align: center;
    margin-bottom: 20px;
    font-family: "Arial";
	 @media ${devices.desktop} {
        font-size: 4rem;
    }
`;

export const OtpDivButton = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

export const OtpResendButton = styled(OtpDivButton)`
   margin-top: 25%;
      @media ${devices.laptopL} {
		 margin-top: 25%;
        font-size: 4rem;
    }
    @media ${devices.desktop} {
		 margin-top: 25%;
        font-size: 4rem;
    }

`

export const OtpButton = styled.button`
	background-color: #020528;
	color: #ffffff;
	width: 15%;
	height: 53px;
	margin-left: auto;
	margin-right: auto;
	font-size: 20px;
	border-radius: 8px;
	cursor: pointer;
	font-weight: 500;
	&:hover {
		color: #ffffff;
		background-color: #000000;
	}
	 @media ${devices.desktop} {
		margin-top: 4rem;
        height: 6rem;
		font-size: 2.5rem;
	 }
`;

export const OTPInputContainer = styled.div`
	margin: 3rem 0;
	display: flex;
	justify-content: center;
	 @media ${devices.desktop} {
		margin-top: 6rem;
        font-size: 2.5rem;
    }
`;

export const OtpFields = styled.input`
	background-color: #f9f9f9;
	margin: 1.3rem .5rem;
	padding: 5px;
	cursor: pointer;
	border: 1px solid black;
	border-color: #020528;
	border-radius: 7px;
	width: 50px;
	height: 50px;
	text-align: center;
	&:hover {
		border-color: #ffd06a;
	}
	 @media ${devices.desktop} {
		width: 6rem;
	   height: 6rem;
	   margin: 1.3rem 1rem;
    }
`;
export const OTPBox = styled.div`
  margin-right: 1.4rem;
  display: flex;
`;

export const OTPBox2 = styled.div`
  margin-left: 1.4rem;
  display: flex;
`;

export const OTPtext = styled.p`
  font-size: 14px;
  color: #000000;
  display: flex-box;
  text-align: center;
    font-family: "Arial";
	 @media ${devices.desktop} {
		margin-top: 8rem;
        font-size: 2.5rem;
    }
`;

// Resend OTP CODE
export const ResendOTP = styled.button`
font-size: 0.9rem;
border: none;
margin-top: 20px;
width: 20%;
text-align: center;
color: red;
font-family: 'IBM Plex Sans Condensed', sans-serif;
background-color: transparent;
cursor: pointer;
z-index: 99;

@media ${devices.desktop} {
		margin-top: 5rem;
        font-size: 2.5rem;
    }
`