
import Z from '../Assests/Images/zambian-kwacha.png'
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import StorageIcon from '@mui/icons-material/Storage';
import ReceiptIcon from '@mui/icons-material/Receipt';
import InventoryIcon from '@mui/icons-material/Inventory';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { FinanceIcon } from '../Assests/Styles/SwitchPageStyales';


export const SwitcBoardData = [
    {href: 'accounting', title: 'Finance', icon:<FinanceIcon>ZMK</FinanceIcon>},
    // {href: 'http://localhost:3000', title: ' HR', icon: <SensorOccupiedIcon />},
    // {href: 'http://localhost:3000', title: 'Administrators', icon: <StorageIcon />},
    // {href: 'http://localhost:3000', title: 'Sales', icon: <ReceiptIcon />},
    // {href: 'http://localhost:3000', title: 'Purchasing', icon: <InventoryIcon />},
    // {href: 'http://localhost:3000', title: 'LMS', icon: <CreditScoreIcon />}
]