import { GlobalStyles } from "./Assests/Styles/GlobalStyles";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Auth from "./Pages/AuthPages/Auth";
import Otp from "./Pages/AuthPages/otp_ui";
import SwitchPage from "./Pages/SwitchBoard/SwitchPage";

const App = () => {
  return (
    <>
    <GlobalStyles />
    <BrowserRouter>
    <Routes>
      <Route path="/" element={< Auth />} />
      <Route path="/otp" element={< Otp />} />
      <Route path="/switch_page" element={< SwitchPage /> } />
    </Routes>
  </BrowserRouter>

    </>
   
  );
}

export default App;
