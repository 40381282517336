import styled from "styled-components";

export const SwitchBoardContainer = styled.div`
  width: 100%;
  background-color: #f9f9f9;
  height: 100vh;
  overflow: hidden;
`;

export const CountryPicker = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 20px auto;
  margin-top: 2.5rem;
`;

export const CounrtyHeading = styled.p`
  font-size: 1.1rem;
  font-weight: 400;
`;

export const SelectCountry = styled.select`
  font-size: 1.3rem;
  padding: 5px;
  margin-left: 15px;
  border: 1px solid #d6d9de;
  :focus {
    outline: 0;
    border: 1px solid #ccc;
    background: #020528;
    color: #fff;
  }
`;
export const CountryOption = styled.option`
  width: 100%;
`
export const CountryCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  width : 95%;
  margin: .5% auto;
  background-color: #f9f9f9;
`;

export const CardLink = styled.a`
  position: relative;
  width: 12rem;
  margin: 0 auto;
  text-align: center;
  height: 15rem;
  color: #fff;
  border-radius: 15px;
  border: 1px solid #ccc;
  background-color: #fff;
  :hover{
    cursor: pointer;
  }
`;
export const TitleHeader = styled.h3`
  font-size: 1.15rem;
  padding: 1rem;
  font-weight: 500;
  color: #000;
  cursor: pointer;
`;

export const Heading = styled.h4`
  color: #020528;
  text-align: center;
  font-weight: 200;
  font-size: 1.2rem;
  margin: 2.5rem 0 4rem 0;
  font-family: Arial, Helvetica, sans-serif;
`;

export const Icons = styled.div`
  color: #ff8a00;
  height: 2rem;
  margin: 5rem 0 1rem 0;
  transform: scale(2.5);
`;

export const ZMK = styled.img`
  width: 1.2rem;
  height: 1.2rem;
  color: #ff8a00;
`;

export const FinanceIcon = styled.p`
  color: #ff8a00;
  font-weight: 600;
`;

