import { useState, useEffect } from 'react';
import { SwitchBoardContainer ,TitleHeader,
        CountryCard, Icons,Heading,
        CardLink, CountryPicker, CounrtyHeading, 
        CountryOption, SelectCountry,
       } from '../../Assests/Styles/SwitchPageStyales'
import DashboardNav from '../../Layout/Header/DashboardNav';
import { SwitcBoardData } from '../../Untils';
import { useNavigate} from 'react-router-dom'
import { useLoginMutation } from '../../Redux/Auth/authApiSlice';


function SwitchPage () {
 
  const [login, { isLoading }] = useLoginMutation()
  const [Info, setInfo] = useState([])

    let OTPDATA = [
      {
        "API_KEY": "pcZ$882193635247$63bd0f65a0d011.78149156",
        "RID": 7,
        "operation": "READ",
        "object": "erp-switchboard-tbl",
        "switch_op_id": "1",
        "unique_identifier": "",
        "payload": []
      }
    ]

// mount response data
useEffect(() => {
  const switchData = async () => {
   setInfo(await login(OTPDATA).unwrap());
  }
  switchData()
},[])

//switch to respective url
const SwitchUrl = (page) => {
window.location.href = `https://erp.premiercredit.co.zm/${page}`;
}

  return (
        <SwitchBoardContainer>
          <DashboardNav />
          <CountryPicker>
              <CounrtyHeading>Choose a Country : </CounrtyHeading> 
              <SelectCountry name="country_list" required> 
              <CountryOption value="zambia">Zambia</CountryOption>
              <CountryOption value= "zimbambwe">Zimbambwe</CountryOption>
              </SelectCountry> 
          </CountryPicker>
          <Heading>To proceed! choose a department you belong to below</Heading>
          <CountryCard>
          {SwitcBoardData.map((s, i)=>
          <CardLink onClick={()=>SwitchUrl(s.href)} key={i}>
              <Icons>
                {s.icon} 
              </Icons>
              <TitleHeader>
                  {s.title}
              </TitleHeader>
          </CardLink>
          )}
          </CountryCard> 
        </SwitchBoardContainer>
  )
}

export default SwitchPage
