import React from 'react'
import { Logo, Navbar } from '../../Assests/Styles/NavBarStayles'
import LogoImage from '../../Assests/Images/logo-orange.png';

const Navigation = () => {
 
  return (
      <Navbar>
        <Logo src={LogoImage} alt='Premier Credit'/>
      </Navbar>
  )
}

export default Navigation
