import React from 'react'
import { Logo, DashNavbar, ProfileContainer, LogoutButton, ProfileIcon } from '../../Assests/Styles/NavBarStayles'
import LogoImage from '../../Assests/Images/logo-black.png';
import { Link, useNavigate } from 'react-router-dom'
//Redux
import { useSendLogoutMutation } from '../../Redux/Auth/authApiSlice'

const DashboardNav = () => {
	const [sendLogout, {
		isLoading,
		isSuccess,
		isError,
		error
	}] = useSendLogoutMutation()
	const navigate = useNavigate()


	const switchPage = () => {
	 //navigate("/")
	 console.log("logout")
	 sendLogout()
	}

	return (
		<DashNavbar>
			<Logo src={LogoImage} alt='Premier Credit'/>
			<ProfileContainer>
				<LogoutButton onClick={switchPage}>Sign out</LogoutButton>
				<ProfileIcon />
			</ProfileContainer>
		</DashNavbar>
	)
}

export default DashboardNav
