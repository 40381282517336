import React, { useState, useEffect } from 'react'
import FooterLayout from '../../Layout/Footer/Footer'
import Navigation from '../../Layout/Header/Navigation';
import { StyledContainer, StyledContainer2, OtpButton, OtpDivButton, OTPInputContainer, OtpFields, OTPHeader, OTPBox,
         OTPBox2, OTPtext, ResendOTP, OtpResendButton } from '../../Assests/Styles/card';
//Redux
import { useDispatch , useSelector} from 'react-redux'
import { setCredentials } from '../../Redux/Auth/authSlice'
import { useLoginMutation, useResendOTPMutation } from '../../Redux/Auth/authApiSlice'
import { useNavigate } from 'react-router-dom';
import toast, { Toaster }from 'react-hot-toast';

const OTP = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const ResendData = useSelector((state) => state.auth.user)
    const [OTP, setOTP] = useState([])
    const [ switchData, setSwitchData] = useState(!true)
    const [errMsg, setErrMsg] = useState('')
    const [ nu, setNu ] = useState(3)
    const [optCode, setOptCode] = useState(
        {
            otp_1: "",
            otp_2: "",
            otp_3: "",
            otp_4: "",
            otp_5: "",
            otp_6: ""
        }
    )

   
    const [login, { isLoading }] = useLoginMutation()
    const [resendOTP, { isFetching }] = useResendOTPMutation()

   
     // Error Alerts
     const notifyError = () => {
     toast.error('ERROR! check OTP code again!');
     };

    const [otpValues, setotpValues] = useState({
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: "",
        otp5: "",
        otp6: ""
    });

    const HandleInput = (e) => {
        const {maxLength, value, name} = e.target;
        setOptCode({...optCode, [name]: value})
        const [fieldName, otpIndex] = name.split("_");
        //Checking if the index is the greater than maxLength
        if (value.length >= maxLength) {
            if (parseInt(otpIndex) < 7) {
                const nextSibling = document.querySelector(`input[name=otp_${parseInt(otpIndex) + 1}]`);
                // Check if on last input
                if (nextSibling !== null) {
                    nextSibling.focus();
                }
            }
        }
        if (value.length < maxLength) {
            if (parseInt(otpIndex) < 7) {
                const nextSibling = document.querySelector(`input[name=otp_${parseInt(otpIndex) - 1}]`);
                // Check if on last input
                if (nextSibling !== null) {
                    nextSibling.focus();
                }
            }
        }
        setotpValues({
            ...value,
            [`opt_${otpIndex}`]: value
        })
      
    };



  // send values to backend 
  const handleSubmit = async(e) => {
    e.preventDefault();
    const{ email } = ResendData
    const{
        otp_1,
        otp_2,
        otp_3,
        otp_4,
        otp_5,
        otp_6 } = optCode
       
       let data = [
        {
          "API_KEY": "pcZ$882193635247$63bd0f65a0d011.78149156",
          "RID": 4,
          "OTP_OP_ID": 3,
          "email": email,
          "otp": `${otp_1}${otp_2}${otp_3}${otp_4}${otp_5}${otp_6}`
        }
      ]
  
    try {
        //verify otp api
        const results = await login(data).unwrap()
       if (results.code_response === 1){
           dispatch(setCredentials(results))
           setOptCode({})
           navigate('/switch_page')
        } else if (results.code_response === 0){
            notifyError()
          setErrMsg('Incorrect Code');
        }
      } catch (err) {
        if (err) {
            notifyError()
            console.log(err)
            setErrMsg('No Server Response');
        } else if (err.status === 400) {
            setErrMsg('Missing email or Password');
        } else if (err.status === 401) {
            setErrMsg('Unauthorized');
        } else {
            setErrMsg(err.data?.message);
        }
    }
 
  }

    // resend OTP
    const resendOTPFunction = async(e) => {
        const{ email, password} = ResendData
        let data = [{
            "API_KEY": "pcZ$882193635247$63bd0f65a0d011.78149156",
              "RID": 1,
              "email": email,
              "pass": password
          }]
          
      e.preventDefault();
      try {
          const results = await resendOTP(data).unwrap()
        if (results.code_response === 1){
            dispatch(setCredentials(results))
         } else if (results.code_response === 0){
           setErrMsg('Incorrect Email or Password');
          }
        }
         catch (err) {
          if (err) {
            console.log(err)
              setErrMsg('No Server Response');
          } else if (err.status === 400) {
              setErrMsg('Missing email or Password');
          } else if (err.status === 401) {
              setErrMsg('Unauthorized');
          } else {
              setErrMsg(err.data?.message);
          }
      }
    }

    return (
        <>
            < Navigation />
            <StyledContainer>
                <StyledContainer2 onSubmit={handleSubmit}>
                    <OTPHeader>OTP</OTPHeader>
                    <OTPtext>Enter the 6-Digit Code that was sent to your Email.</OTPtext>
                    <OTPInputContainer>
                    <Toaster
                     position="bottom-left"
                     reverseOrder={false}
                     duration = "8000"
                     /> 
                        <OTPBox>
                            <OtpFields name="otp_1" type={'tel'} maxLength={1} onInput={HandleInput} />
                            <OtpFields name="otp_2" type={'tel'} maxLength={1} onInput={HandleInput} />
                            <OtpFields name="otp_3" type={'tel'} maxLength={1} onInput={HandleInput} />
                        </OTPBox>
                        <OTPBox2>
                            <OtpFields name="otp_4" type={'tel'} maxLength={1} onInput={HandleInput} />
                            <OtpFields name="otp_5" type={'tel'} maxLength={1} onInput={HandleInput} />
                            <OtpFields name="otp_6" type={'tel'} maxLength={1} onInput={HandleInput} />
                        </OTPBox2>
                    </OTPInputContainer>
                    <OtpDivButton>
                        <OtpButton>
                        {isLoading ? "Verifying..." : "Verify"}
                        </OtpButton>
                    </OtpDivButton>
                </StyledContainer2>
                <OtpResendButton>
                <ResendOTP onClick={resendOTPFunction}>Resend OTP</ResendOTP>
                </OtpResendButton>
                < FooterLayout />
            </StyledContainer>
        </>
    )
}

export default OTP;
